import { useEffect, useState } from 'react';
import Header from './layout/header';
import Footer from './layout/footer';
import POS from './pos';
import Login from './layout/login';


function App() {

  const [ showLogin, setShowLogin ] = useState<boolean>(true);

    //Show hide pos 

  useEffect(() => {
      const t = localStorage.getItem('t');
      if (t !== null) setShowLogin(false);
  }, []);
  
  return (
    <>
    <Header/>
    <main style={{ minHeight:'100%' }} className='pt-5'>
      <div className="container-fluid">
        {(showLogin === true) && <Login />}
        {(showLogin === false) && <POS />}
      </div>
    </main>
    <Footer/>
    </>
  );
}

export default App;
