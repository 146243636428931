import axios from 'axios';

import { IDoliCustomer, IDoliEmitter } from '../@types/invoice';
import { IDoliProduct } from '../@types/pos';
// import { IToken } from '../@types/login';

//const url = 'http://localhost:8000/api/';

export const login = async ( email: string, password: string) => {
    // const data = { 'email': email, 'password': password};
    
    if (email === 'admin@posweb.com' && password === 'n0ts3cur3.') {
        return await'issamsyktgnfwelqtoimnmikgqfuwhyj';
    }

    // return await axios
    // .post(`${url}login`, data)
    // .then((r) => {
    //     if (!r) throw new Error("Can't parse response");
    //     if (! r.data ) throw new Error("Response is empty");
    //     const rsp: IToken = { token: r.data.user.toString() }
    //     return rsp;
    // })

}


const PROXY_GET_PRODUCT="https://pnavarro.tech/scripts/get_by_barcode.php";


export const getByBarCode = async (barcode: string) => {

    return await axios
    .get(`${PROXY_GET_PRODUCT}?barcode=${barcode}`)
    .then((r) => {
        if (!r) throw new Error("Can't parse response");
        if (! r.data ) throw new Error("Response is empty");

        const rsp: IDoliProduct = {
            id: r.data.id,
            ref: r.data.ref,
            label: r.data.label,
            description: r.data.description,
            price: r.data.price,
            price_ttc: r.data.price_ttc,
            tva_tx: r.data.tva_tx,
            barcode: r.data.barcode,
            discount: 0,
            claveprodserv: r.data.claveprodserv,
            umed: r.data.umed,
            noidenticfdi: r.data.noidenticfdi,
            exentoiva: r.data.exentoiva,
            objimp: r.data.objimp,            
        }

        return rsp;
    })

};


// const PROXY_GET_COMPANY="https://pnavarro.tech/scripts/company.php";


export const getCompany = async (qsParams: string | null) => {

    return await axios
    .get(`${PROXY_GET_CUSTOMER}` + (qsParams !== null ? qsParams : '') )
    .then((r) => {
        if (!r) throw new Error("Can't parse response");
        if (! r.data ) throw new Error("Response is empty");

        const rsp: IDoliEmitter = {
            id: 219378219378,
            tax_id : 'emisorRFC',
            company_name : 'nombre',
            tax_regime : 'emisorRegimen',
            zip_code : 'codigoPostal',
        }

        return rsp;
    })

};



const PROXY_GET_CUSTOMER="https://pnavarro.tech/scripts/customer.php?idprof1=";


export const getCustomer = async (tax_id: string) => {

    return await axios
    .get(`${PROXY_GET_CUSTOMER}${tax_id}`) 
    .then((r) => {
        if (!r) throw new Error("Can't parse response");
        if (! r.data ) throw new Error("Response is empty");

        const rsp: IDoliCustomer = {
            id: r.data.id,
            name: r.data.name,
            idprof1: r.data.idprof1,
            remise_percent: r.data.remise_percent,
            absolute_discount: r.data.absolute_discount,
            tax_regime: r.data.tax_regime,
            zip_code: r.data.zip_code,
        
        }

        return rsp;
    })

};


const PROXY_GET_CFDI="https://pnavarro.tech/scripts/auribox_ws.php?t=r";
// const PROXY_GET_CFDI="http://localhost/php_scripts/";

export const getCFDI = async (params: any) => {
    return await axios.post(`${PROXY_GET_CFDI}`, params)
    .then(r => {
        
        if ( r.data.status.toUpperCase() === 'OK') {
            return r.data.file_name
        }
        throw new Error("Solicitud fallida: " + r.data.message);
    })
}