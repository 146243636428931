import React from "react";

const Footer = () => {
    return (<>
        <footer className="footer mt-auto py-3 bg-light">
        <div className="container">
            <span className="text-muted">Reactive POS WebApp by &lt;g33k&gt;</span>
        </div>
        </footer>
    </>);
}

export default Footer;