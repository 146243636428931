import { useEffect, useState } from 'react';
import { getByBarCode, getCFDI } from '../service/api';
import { productPlaceholder } from '../@types/pos';
import { IComprobante, IConcepto, IEmisor, IReceptor } from '../@types/invoice';
import { IDoliProduct, IProductSale } from '../@types/pos';

const POS = () => {

    const [displaySale, setDisplaySale] = useState<Boolean>(true);
    const [pieces, setPieces] = useState<number>(0);

    const [searchBarCode, setSearchBarCode] = useState<string>('');
    const [product, setProduct] = useState<IDoliProduct>(productPlaceholder);

    const [productsToSale, setProductsToSale] = useState<IProductSale[]>([]);
    const [subtotal, setSubtotal] = useState<number>(0);

    // calling api for find products when searchBarCode.length === 12
    useEffect( () => {
        if (searchBarCode.length === 12 ) {
            getByBarCode(searchBarCode)
            .then((r) => { setProduct(r as IDoliProduct); })
            .catch(e => {
                if ( e.response.status === 404) return alert('Producto no encontrado');
                if ( e.response.status !== 404) return alert('Error en el Servidor  ' + e.message);
            });
        }
    }, [ searchBarCode ]);


    useEffect(() => {  if (productsToSale.length >= 0) { updateSum(); } }, [productsToSale]);

    // CFDI Info
    const [ emisor, setEmisor] = useState<IEmisor>();
    const [ receptor, setReceptor] = useState<IReceptor>();
    const [ invoice_usages, ] = useState<string[]>(["S01", "G03"]);
    const [ comprobante, setComprobante ] = useState<IComprobante>();

    const [ moneda ] = useState<string>('MXN');
    const [ formaDePago, setFormaDePago ] = useState<string>('99');
    const [ tipoDeComprobante ] = useState<string>('I');
    const [ metodoDePago, setMetodoDePago ] = useState<string>('PUE');
    const [ lugarExpedicion ] = useState<string>('42501');
    const [ version ] = useState<string>('4.0');
    const [ exportacion, setExportacion ] = useState<string>('01');

    const [ conceptos, setConceptos ] = useState<IConcepto[]>([]);

    const [customer_name, setCustomerName] = useState<string>(); // name on ws: nombre
    const [customer_idprof1, setCustomerIdprof1] = useState<string>(); // name on ws: rfc
    const [customer_tax_regime, setCustomerTaxRegime] = useState<string>("616"); // name on ws: regimenFiscal
    const [customer_zip_code, setCustomerZipCode] = useState<string>(); // name on ws: regimenFiscal
    const [invoice_usage, setInvoiceUsage] = useState<string>('S01');
    
    const [ invoice_subtotal, setInvoiceSubTotal ] = useState<number>(0);
    const [ invoice_tax, setInvoiceTax ] = useState<number>(0);
    const [ invoice_total, setInvoiceTotal ] = useState<number>(0);

    const [ is_ws_working, setIsWSWorking ] = useState<boolean>(false);

    const [ is_done, setIsDone ] = useState<boolean>(false);

    // displaySale === false (the invoice component is shown)
    useEffect(() => {
        if ( displaySale === false && productsToSale.length >= 1) {
            setInvoiceSubTotal( productsToSale.reduce((prev, curr) => 
                    parseFloat(prev.toString() || '0') + parseFloat(curr.subTotalAmount.toString() || '0'), 0) 
                    );
                
            setInvoiceTax( productsToSale.reduce((prev, curr) => 
                parseFloat(prev.toString() || '0') + parseFloat(curr.taxAmount.toString() || '0'), 0)
            );
    

            setInvoiceTotal( productsToSale.reduce((prev, curr) => 
                parseFloat(prev.toString() || '0') + parseFloat(curr.totalAmount.toString() || '0') , 0)
            );

        }

    }, [displaySale, productsToSale]);


    // Setting default values for emitter (DOLIBARR emitters api were NOT provided)
    useEffect(()=>{
        const e: IEmisor = {
            emisorRFC:'EKU9003173C9',
            nombre: 'ESCUELA KEMPER URGATE',
            emisorRegimen: '601',
            codigoPostal: '42501',
        };
        setEmisor(e);
    },[]);

    // Filling the customer object when all settings are ready
    useEffect(() => {
        if (
            customer_name !== undefined && customer_name !== '' && 
            customer_idprof1 !== undefined && customer_idprof1 !== '' && 
            customer_tax_regime !== undefined && customer_tax_regime !== '' && 
            customer_zip_code !== undefined && customer_zip_code !== '' && 
            invoice_usage !== undefined && invoice_usage !== ''
        ) {
            const r: IReceptor = {
                rfc: customer_idprof1, 
                usoCFDI: invoice_usage, 
                nombre: customer_name, 
                regimenFiscal: customer_tax_regime, 
                codigoPostal: customer_zip_code, 
            }
            setReceptor(r);
        }
    }, [customer_name, customer_idprof1, customer_tax_regime, customer_zip_code, invoice_usage ]);


    // Filling the "comprobante" object when emitter & receiver are filled
    useEffect(()=>{

        if ( 
            moneda !== '' &&
            formaDePago !== '' &&
            tipoDeComprobante !== '' &&
            invoice_subtotal > 0 &&
            invoice_total > 0 &&
            metodoDePago !== '' &&
            lugarExpedicion !== '' &&
            version !== '' &&
            exportacion !== '' ) 
        {
             setComprobante({
                 formaDePago: formaDePago.toString(), 
                 tipoDeComprobante: tipoDeComprobante.toString(), 
                 subTotal: parseFloat(invoice_subtotal.toString()).toFixed(2), // (subtotal - tax).toString() ,
                 total: parseFloat((invoice_total).toString()).toFixed(2), 
                 metodoDePago: metodoDePago.toString(), 
                 lugarExpedicion: lugarExpedicion.toString(), 
                 serie: '--', 
                 folio: '--', 
                 moneda: moneda.toString(),
                 version: version.toString(), 
                 exportacion: exportacion.toString(), 
             } as IComprobante);

            } 

    },[ moneda, formaDePago, tipoDeComprobante, metodoDePago, lugarExpedicion, version, exportacion, invoice_subtotal, invoice_total ]);


    // Fill IConceptos when comprobante is ready
    useEffect(() => {

        if (comprobante !== undefined && productsToSale.length >= 1) {
            /**
            * 
            *    Listo ya tu no tienes que estar haciendo calculos lo unico que tienes que hacer es
            * 
            *    multiplicar los precios unitarios * por cantidades
            *     y las respectiva sumatorias
            *    ------------------------------------------------------------------------
            *        "label": "ProductoIVA",
            *        "price": "172.41000000", -> precio unitario
            *        "price_ttc": "200.00000000", precio unitario + IVA
            *        "price_base_type": "TTC", -> incluye IVA el producto
            *        "tva_tx": "16.0000", -> tasa IVA
            *        "barcode": "020000000016", -> codigo de barras
            *    -------------------------------------------------------------------------
            *        "label": "ProductoNoIVA",    
            *        "price": "200.00000000", -> precio unitario 
            *        "price_ttc": "200.00000000", -> precio unitario (sin calculos de impuestos) el valor de este campo en este ejemplo debe ser el mismo de price
            *        "price_base_type": "HT", -> no incluye IVA el producto
            *        "tva_tx": "0.0000", -> Tasa IVA
            *        "barcode": "020000000017", -> codigo de barras
            *  
            */
            let arr : IConcepto[] = [];

            productsToSale.map((v, k) => {

                return arr.push(
                    {
                        cantidad: v.pieces.toString(),
                        descripcion: v.ref.toString(),
                        claveProdServ: v.claveprodserv.toString(),
                        unidad: v.umed.toString(),
                        valorUnitario: parseFloat(v.price.toString()).toFixed(2),
                        importe: parseFloat(v.subTotalAmount.toString()).toFixed(2),
                        impuesto: "002",
                        tipoFactor: 'Tasa',
                        base: parseFloat(v.subTotalAmount.toString()).toFixed(2),
                        tasa: parseFloat((v.tva_tx / 100).toString()).toFixed(6),
                        importeImpuesto: parseFloat(v.taxAmount.toString()).toFixed(2),
                        objetoImp: v.price_ttc !== v.price ? "02" : "04", // @TODO when v.objim !== "02"
                    } as IConcepto                    
                    );
            });

            setConceptos(arr);

    }

    }, [comprobante, productsToSale]);
 
    // refresh window when all the process is done
    useEffect(() => {
        if ( is_done === true ) { window.location.reload(); }
    }, [is_done]);

    // Products Component
    const Products = ( props: any ) => {

        return (<>
            <div className={props.display ? "row" : "d-none"}>

                <div className="col-12">
                    <div className="input-group mb-3 p-1">
                        <span className="input-group-text bg-danger  text-white" onClick={ (e) => {setSearchBarCode('')}}><i className="fas fa-remove"></i>&nbsp;</span>
                        <input value={searchBarCode} className="form-control" type="search" placeholder="Ingresar Código de Barras" aria-label="" onChange={ (e) => setSearchBarCode(e.target.value) } />

                    </div>

                </div>
                <div className="card-group">
                <div className="card rounded-3 shadow-sm">
                    <div className="card-header text-wrap">
                        <div className="card-title fw-bold">
                            <div className="fas fa-tags"></div>&nbsp;
                            { product.ref }
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">

                            <div className="col-sm-12 col-md-7 text-wrap">
                                <p className='bg-light text-dark p-1 rounded-4 shadow-sm'>
                                    { product.label } <br/>
                                </p>

                                <p className='bg-light text-dark p-1 rounded-4 shadow-sm'>
                                    { product.description } <br/>
                                </p>
                            </div>

                            <div className="col-sm-12 col-md-5 text-end">

                                <div className="row bg-light p-1 text-dark rounded-4 shadow-sm">

                                    <div className="col-sm-6 col-md-5 text-start">
                                        Importe
                                    </div>

                                    <div className="col-sm-6 col-md-7 text-end">
                                        <div className="text-primary fw-bold">
                                            {parseFloat(product.price.toString()).toFixed(2) }
                                        </div>
                                    </div>

                                </div>

                                <div className="row bg-light p-1 text-dark rounded-4 shadow-sm">

                                    <div className="col-sm-6 col-md-5 text-start">
                                        Imptos
                                    </div>

                                    <div className="col-sm-6 col-md-7 text-end">
                                        <div className="text-primary fw-bold">
                                            {parseFloat((product.price_ttc - product.price).toString()).toFixed(2) }
                                        </div>
                                    </div>

                                </div>


                                <div className="row bg-light p-1 text-dark rounded-4 shadow-sm">

                                    <div className="col-sm-6 col-md-5 text-start">
                                        Total
                                    </div>

                                    <div className="col-sm-6 col-md-7 text-end">
                                        <div className="text-primary fw-bold">
                                            {parseFloat((product.price_ttc).toString()).toFixed(2) }
                                        </div>
                                    </div>

                                </div>



                            </div>

                        </div>

                    </div>
                    <div className="card-footer">
                        <div className="row mx-auto">
                                    <button className="btn btn-outline-primary btn-block" type="button" id="button-addon2" onClick={() => addProduct(product)}>Agregar <div className="fas fa-cart-plus"></div></button>
                            <div className="col-12">
                                <div className="input-group mb-3">
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            </div>

        </>);
    }


    // add product to sale
    const addProduct = (product: IDoliProduct) => {
        if (product === productPlaceholder) { alert('Selecciona un Producto'); return;}

        const foundProductInSale = productsToSale.find(r => {return r.barcode === product.barcode});
        if ( ! foundProductInSale ) {


            const newProduct: IProductSale = {
                id: product.id,
                pieces: 1,
                ref: product.ref,
                label: product.label,
                description: product.description,
                price: product.price,
                price_ttc: product.price_ttc,
                tva_tx: product.tva_tx,
                barcode: product.barcode,
                claveprodserv: product.claveprodserv,
                umed: product.umed,
                noidenticfdi: product.noidenticfdi,
                exentoiva: product.exentoiva,
                objimp: product.objimp,
                subTotalAmount: parseFloat(product.price.toString()),
                taxAmount: parseFloat(product.price_ttc.toString()) - parseFloat(product.price.toString()),
                totalAmount: parseFloat(product.price.toString()) + (parseFloat(product.price_ttc.toString()) - parseFloat(product.price.toString()))
            }

            setProductsToSale([...productsToSale, newProduct]);

        } else {

            foundProductInSale.pieces = parseInt(foundProductInSale.pieces.toString()) + 1;
            // # @TODO: Calculate discount from Customer
            foundProductInSale.subTotalAmount = ( parseFloat(foundProductInSale.pieces.toString()) * parseFloat(product.price.toString()) );
            let tax = (parseFloat(foundProductInSale.price_ttc.toString()) - parseFloat(foundProductInSale.price.toString())) * parseFloat(foundProductInSale.pieces.toString());
            foundProductInSale.taxAmount = parseFloat(tax.toString());
            foundProductInSale.totalAmount = parseFloat(foundProductInSale.subTotalAmount.toString()) + parseFloat(foundProductInSale.taxAmount.toString());

            const updatedProductList: IProductSale[] = productsToSale.filter((el) => {  return (el.barcode !== foundProductInSale.barcode); } );

            updatedProductList.push(foundProductInSale);

            setProductsToSale(updatedProductList);
        }

        updateSum();
    }


    const updateSum = () => {

        setSubtotal(
            productsToSale.reduce((prev, curr) => 
                parseFloat(prev.toString() || '0') + parseFloat(curr.subTotalAmount.toString() || '0'), 0)
            );
        setPieces( 
            productsToSale.reduce((prev, curr) => 
                parseInt(prev.toString() || '0') + parseInt(curr.pieces.toString() || '0'), 0)
            );

    }

    // remove product from sale
    const removeProduct = (product: IProductSale, key: number) => {
        setProductsToSale(productsToSale.filter((el) => {
            let d: IProductSale = el;
            return (d.barcode !== product.barcode);
        } ));

    }


    const Sale = ( props: any ) => {
        return (<>
            <div className={props.display ? "row" : "d-none"}>
                <div className="col-sm-12">

                    <div className="card rounded-3 shadow-sm">
                        <div className="card-header">
                            <div className="card-title">
                                <i className="fa-solid fa-cash-register"></i> Venta
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table text-center">
                                    <thead>
                                    <tr>
                                        <th style={{ width: "50%" }}>Producto</th>
                                        <th style={{ width: "10%" }}>Cantidad</th>
                                        <th style={{ width: "10%" }}>P. Unitario</th>
                                        <th style={{ width: "10%" }}>Importe</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            productsToSale.length > 0 &&
                                            productsToSale.map((product: IProductSale, key) => {
                                                return (<tr key={key}>
                                                    <th scope="row" className="text-start">
                                                        <button className="btn-danger" type="button" onClick={e => removeProduct(product, key)}>
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                        &nbsp;
                                                        {`${product.label} ${product.ref}`}
                                                    </th>
                                                    <td>{ product.pieces }</td>
                                                    <td>{ product.price  }</td>
                                                    <td>{ parseFloat(product.subTotalAmount.toString()).toFixed(2)  }</td>
                                                </tr>)
                                            })
                                        }

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td  className="text-end" colSpan={3}><strong>IMPORTE $ </strong></td>
                                            <td>{parseFloat(subtotal.toString() || '0').toFixed(2) } </td>
                                        </tr>
                                    </tfoot>
                                </table>
                                </div>

                            </div>
                        <div className="card-footer">
                            <div className="row justify-content-center">
                                <div className="col-sm-12 col-md-4 text-center">
                                    <strong className={(productsToSale.length > 0) ? "text-primary" : 'd-none'}>
                                        <button className="btn btn-outline-success" onClick={() => setDisplaySale(!displaySale)}>
                                            <i className="fa-solid fa-file-invoice"></i>&nbsp;C.F.D.I
                                        </button>
                                    </strong>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>);
    }

    
    // requesting CFDI 
    const callAuriboxWS = () => {
        setIsWSWorking(true);
        getCFDI({
            comprobante: comprobante,
            receptor: receptor,
            emisor: emisor, 
            conceptos: conceptos
        }).then(r => {
            window.open("https://pnavarro.tech/" + r);
            setIsDone(true);
        }).catch(e => alert(e.message));
    }
    

    const InvoiceForm = ( props: any ) => {


        return (
            <div className={props.display ? "container-fluid" : "d-none"}>
                <div className="card">
                    <div className="card-header">
                        <div className="row g-2">
                            <div className="col-sm-12 col-md-4">
                                <h4>Emisor</h4>
                            </div>

                            <div className="col-sm-12 col-md-8 text-end">
                                <button className="w-10 btn btn-outline-secondary" type="button" onClick={e => setDisplaySale(!displaySale)}>
                                    <i className="fa-solid fa-cash-register"></i>  Venta
                                </button>

                            </div>


                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="emisor_nombre" className="form-label">Nombre / Razón Social</label>
                                <input type="text" className="form-control" id="emisor_nombre" placeholder="" value={emisor?.nombre} required readOnly/>
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="emisor_rfc" className="form-label">R.F.C.</label>
                                <input type="text" className="form-control" id="emisor_rfc" placeholder="XXXX999999XX" required value={emisor?.emisorRFC} readOnly/>
                            </div>

                            <div className="col-sm-12 col-md-2">
                                <label htmlFor="emisor_regimen" className="form-label">Régimen Fiscal</label>
                                <input type="text" className="form-control" id="emisor_regimen" placeholder="XXXX999999XX" required value={emisor?.emisorRegimen} readOnly/>
                            </div>


                            <div className="col-sm-12 col-md-2">
                                <label htmlFor="emisor_codigo_postal" className="form-label">Código Postal</label>
                                <input type="text" className="form-control" id="emisor_codigo_postal" placeholder="53227" required value={emisor?.codigoPostal} readOnly/>
                            </div>

                        </div>

                    </div>

                    <div className="card-body">

                        <div className="row pt-3 g-5">

                            {/* Invoice Header Data */}

                            <div className="col-sm-12 col-md-4 h-100">
                                <div className="row">
                                    <h4 className="mb-3">Comprobante</h4>

                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="formaDePago">Forma Pago</label>
                                            <select name="formaDePago" id="formaDePago" required={true} className="form-control" onChange={e=>setFormaDePago(e.target.value)} value={formaDePago}>
                                                <option value="">Seleccionar</option>
                                                <option value="01" selected>01 - Efectivo</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="metodoDePago">Método Pago</label>
                                            <select name="metodoDePago" id="metodoDePago" required={true} className="form-control" onChange={e=>setMetodoDePago(e.target.value)} value={metodoDePago}>
                                                <option value="">Seleccionar</option>
                                                <option value="PUE" selected>PUE - Pago en Una sola Exhibición</option>
                                                <option value="PDD">PDD - Pago en Parcialidades</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="exportacion">Exportación</label>
                                            <select name="exportacion" id="exportacion" required={true} className="form-control" onChange={e => setExportacion(e.target.value)} value={exportacion}>
                                                <option value="01">01 - No Aplica</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-sm-8">
                                        <div className="form-group">
                                            <label htmlFor="moneda" className="form-label">Moneda</label>
                                            <input type="text" className="form-control" id="moneda" placeholder="MXN" required readOnly={true} value={moneda}/>
                                        </div>
                                    </div>

                                    <div className="py-2 col-sm-12">
                                        <ul className="list-group">
                                            <li className="list-group-item d-flex justify-content-between">
                                                <span>IMPORTE $ </span>
                                                <strong>{parseFloat(invoice_subtotal.toString() || '0').toFixed(2) } </strong>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between">
                                                <span>IMPTOS $ </span>
                                                <strong>{parseFloat(invoice_tax.toString() || '0').toFixed(2) } </strong> 
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between">
                                                <span>TOTAL $ </span>
                                                <strong>{parseFloat(invoice_total.toString() || '0').toFixed(2) } </strong>
                                            </li>

                                        </ul>
                                    </div>

                                </div>
                            </div>



                            {/* Customer CFDI Information */}
                            <div className="col-sm-12 col-md-4 h-100">
                                <h4 className="mb-3">Receptor</h4>


                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="customer_name" className="form-label">Nombre / Razón Social</label>
                                        <input type="text" className="form-control" id="customer_name" value={customer_name}
                                        placeholder="" required onChange={(e)=>{setCustomerName(e.target.value)}}/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="customer_idprof1" className="form-label">R.F.C</label>
                                        <input type="text" className="form-control" id="customer_idprof1" value={customer_idprof1}
                                        placeholder="URE180429TM6" required  onChange={(e)=>{setCustomerIdprof1(e.target.value)}}/>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="invoice_usage" className="form-label">Uso CFDI</label>
                                        <select name="invoice_usage" id="invoice_usage" required={true} className="form-control"
                                        value={invoice_usage} onChange={e=>setInvoiceUsage(e.target.value)}>
                                            <option>Seleccionar</option>
                                            {invoice_usages.length >= 1 && invoice_usages.map((v, i) => {return <option key={i} value={v}>{v}</option>})}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group">

                                        <label htmlFor="customer_tax_regime" className="form-label">Régimen Fiscal</label>
                                        <select name="customer_tax_regime" id="customer_tax_regime" required={true} className="form-control"
                                        value={customer_tax_regime} onChange={e=>setCustomerTaxRegime(e.target.value)}>
                                            <option value="616">616 Sin obligaciones fiscales</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="form-group">

                                        <label htmlFor="customer_zip_code" className="form-label">Código Postal</label>
                                        <input type="text" className="form-control" id="customer_zip_code" value={customer_zip_code}
                                        placeholder="" required onChange={e=>setCustomerZipCode(e.target.value)}/>
                                    </div>
                                </div>

                            </div>


                            {/* Ticket summary column () */}

                            <div className="col-sm-12 col-md-4 h-100">

                                <h4 className="d-flex justify-content-between align-items-center mb-3">
                                    <span className="text-dark">Conceptos</span>
                                    <span className="badge bg-primary rounded-pill">{ productsToSale.length }</span>
                                </h4>

                                <ul className="list-group mb-3"  style={{ height: '100%', minHeight: '250px' }}>
                                    {
                                        productsToSale.length > 0 &&
                                        productsToSale.map((product: IProductSale, key) => {
                                        return (<li className="list-group-item d-flex justify-content-between lh-sm" key={key}>
                                        <div>
                                            <h6 className="my-0">{product.label}</h6>
                                            <p>
                                                <small className="text-sm text-muted">({product.price} X {product.pieces})</small>
                                            </p>
                                        </div>
                                        <br/>
                                        <span className="text-muted">
                                            {parseFloat(product.subTotalAmount.toString()).toFixed(2)}
                                        </span>
                                    </li>)
                                        })
                                    }

                                </ul>


                            </div>


                        </div>

                    </div>

                    <div className="card-footer text-center">
                        <div className={!is_ws_working ? "d-flex w-50 justify-content-end" : "d-none"}>
                            <button className={emisor !== undefined && receptor !== undefined && comprobante !== undefined && conceptos?.length >= 1 ? "btn btn-primary" : "btn btn-primary disabled"} 
                            onClick={callAuriboxWS}><i className="fa fa-paper-plane"></i> Enviar</button>
                        </div>
                        <div className={is_ws_working ? "d-flex w-50 justify-content-end" : "d-none"}>
                        <button className="btn btn-primary disabled"><i className="fa fa-paper-plane"></i> Solicitando CFDI</button>

                        </div>
                        
                    </div>

                </div>


            </div>        
        );

    }


    return (
        <div className="container-fluid">
            <div className="row pt-5">
                <div className="col-sm-12 col-md-5">
                    <Products display={displaySale}/>

                </div>
            
                <div className="col-sm-12 col-md-7">

                    <Sale display={displaySale}/>
                </div>
            </div>
            
            <InvoiceForm display={!displaySale}/>
        </div>
    );
}


export default POS;