import React, { useState, useEffect } from "react";

const Header = () => {

  const [showLogout, setShowLogout] = useState<boolean>(false);
  const [t, setT] = useState<string>('');

  useEffect(() => {
    const t = localStorage.getItem('t');
    if (t === null) {
      setShowLogout(false);
    } else {
      setShowLogout(true)
    }

  }, []);
    
  const logout = () => {
    localStorage.removeItem('t');
    window.location.reload();
  }



  return (
  <header>
    {/* <!-- Fixed navbar --> */}
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="#"><i className="fas fa-store"></i> Reactive POS WebApp</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item text-end">
              <a className={showLogout === true ? "nav-link":"d-none"} href="#" tabIndex={-1} aria-disabled="true" onClick={logout}>Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <hr />
  </header>
      )
}

export default Header;