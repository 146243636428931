import React, { FormEvent, useEffect, useState } from 'react'






import { login as postLogin } from '../service/api';

const Login = () => {
  
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [ showLogin, setShowLogin ] = useState<boolean>(true);

  const submitLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    postLogin(login, password)
    .then(r => {
      localStorage.setItem('t', JSON.stringify(r));
      window.location.reload();
    });

  }

  useEffect(() => {
    const t = localStorage.getItem('t');
    if (t !== null) setShowLogin(false); 
  }, []);


  return (<>
  <main className="container">
    <div className="row justify-content-center p-5">
      <div className="col-5">

        <form method="POST" onSubmit={(e) => {submitLogin(e);}} className={showLogin ? '' : 'd-none'}>
          {/* <img className="mb-4" src="/docs/5.0/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"/> */}
          <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
          
          <div className="form-floating p-1">
            <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" value={login} onChange={e => setLogin(e.target.value.toString())} />
            <label htmlFor="floatingInput">Email address</label>
          </div>
          <div className="form-floating p-1">
            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={e => setPassword(e.target.value.toString())}/>
            <label htmlFor="floatingPassword">Password</label>
          </div>
            
            <div className="checkbox mb-3">
              <label> <input type="checkbox" value="remember-me"/> Remember me </label>
            </div>
            <button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
            <p className="mt-5 mb-3 text-muted">&copy; 2017–2021</p>
          </form>    
      </div>
    </div>
  </main>
    </>);
  }
  
  export default Login;