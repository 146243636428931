export interface IDoliProduct {
    id: number;
    ref: string;
    label: string;
    description: string;
    price: number;
    price_ttc: number;
    tva_tx: number;
    barcode: string;
    discount: number;
    claveprodserv:string;
    umed: string;
    noidenticfdi: string;
    exentoiva: string;
    objimp: string;
}

export interface IProductSale {
    id: number;
    ref: string;
    label: string;
    description: string;
    price: number;
    price_ttc: number;
    tva_tx: number;
    barcode: string;
    pieces: number;
    claveprodserv:string;
    umed: string;
    noidenticfdi: string;
    exentoiva: string;
    objimp: string;
    subTotalAmount: number;
    taxAmount: number;
    totalAmount: number;
}    

export const productPlaceholder: IDoliProduct =  {
    id: -1,
    ref: "#########",
    label: "#########",
    description: "#########",
    price: 0,
    price_ttc: 0,
    tva_tx: 0,
    barcode: '000000000000',
    discount: 0,
    claveprodserv: "#########",
    umed: "#########",
    noidenticfdi: "#########",
    exentoiva: "#########",
    objimp: "#########",


}
